<template>
  <div>
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <a-button class="add-bt" type="primary" @click="$utils.linkTo('/shop/order/selfLiftingPointEdit')">新建自提点</a-button>
    </Breadcrumb>
    <a-table :data-source="list"
             bordered
             :rowKey="record => record.id"
             @change="currentChange"
             :columns="columns"
             :pagination="pagination"
             :scroll="{ x:1500 }">
      <span slot="name" slot-scope="text">
        <a href="#">{{text}}</a>
      </span>
      <span slot="address" slot-scope="text,record">
        {{record.provinceName}}{{record.cityName}}{{record.districtName}}{{ record.address}}
      </span>
      <span slot="action" slot-scope="text,record">
        <a style="margin-right:20px;" @click="$utils.linkTo(`/shop/order/selfLiftingPointEdit?id=${record.id}`)">编辑</a>
        <a style="margin-right:20px;" @click='deleteSelfLiftingPoint(record.id)' href="javascript:;">删除</a>
      </span>
    </a-table>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
export default {
  components: {
    Breadcrumb
  },
  data () {
    return {
      breadList: [
        {
          name: '订单管理',
          url: ''
        },
        {
          name: '自提点',
          url: ''
        }
      ],
      visible: false,
      isQueryLoading: false,
      form: {
        id: ''
      },
      formRefuse: {
        id: '',
        failReason: ''
      },
      columns: [
        { title: '名称', width: 350, ellipsis: true, dataIndex: 'name', key: 'name', scopedSlots: { customRender: 'name' } },
        { title: '地址', width: 450, ellipsis: true, dataIndex: 'address', key: 'address', scopedSlots: { customRender: 'address' } },
        { title: '提货时间', width: 400, dataIndex: 'businessHours', key: 'businessHours' },
        { title: '联系电话', dataIndex: 'addresseeTel', key: 'addresseeTel' },
        { title: '操作', width: 150, fixed: 'right', key: 'action', scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      total: 0,
      list: [],
      selectedRowKeys: []
    }
  },
  created () {
    this.reloadList()
  },
  methods: {
    deleteSelfLiftingPoint (id) {
      this.$store.dispatch('showConfirm', {
        message: '你确定要删除这条内容吗?',
        yes: async _ => {
          this.isLoading = true
          await this.$store.dispatch('http', {
            api: 'selfLiftingPointDelete',
            query: {
              id: id
            },
            error: (e) => {
              this.$store.dispatch('showToast', {
                type: 'error',
                message: e
              })
            },
            success: () => {
              this.isLoading = false
              this.$store.dispatch('showToast', { message: '删除成功' })
              this.reloadList()
            }
          })
        }
      })
    },
    resetForm (formName) {
      this.form = {}
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
        // this.selectedRowKeys = []
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => { }) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => { }) {
      console.log(this.form)
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'selfLiftingPointList',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      this.list = records
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log('分页、排序、筛选变化时触发', val)
      this.pagination.pageSize = val.pageSize
      this.pagination.current = val.current
      this.loadList()
    },
    // 选中事件
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>

<style lang="scss" scoped>
.toolTip{
 display:-webkit-box;
-webkit-line-clamp:3;
-webkit-box-orient:vertical;
overflow:hidden;
}
.tips{
 color:rgba(24, 144, 255,1);
 background:rgba(230,247,255,1);
 padding:10px 50px;
 border:1px solid rgb(215, 231, 247);
 margin-top: 10px;
 :first-child{
   font-weight: bold;
   font-size: 20px;
 }
}
</style>
